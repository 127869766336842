import api from '@/plugins/axios'

const base = '/v1/cobranca/'

export default {
  statusOperacoes () {
    return api.get(`${base}status-operacoes`)
  },
  statusMensagensInadimplencia () {
    return api.get(`${base}status-mensagens-inadimplencia`)
  },
  operacoesAtraso () {
    return api.get(`${base}operacoes-atraso`)
  },
  baixarCCBs (emprestimoId) {
    return api.get(`${base}baixar-ccbs?emprestimoId=${emprestimoId}`, { responseType: 'blob' })
  },
  baixarContratosInvestidores (emprestimoId) {
    return api.get(`https://raw.tutudigital.com.br/api/v1/cobranca/contratos-investidores?emprestimoId=${emprestimoId}`, { responseType: 'blob' })
  },
  baixarIrpfSocios (emprestimoId) {
    return api.get(`https://raw.tutudigital.com.br/api/v1/cobranca/irpf-socios?emprestimoId=${emprestimoId}`, { responseType: 'blob' })
  },
  valorPresente (emprestimoId, data) {
    return api.get(`${base}valor-presente?emprestimoId=${emprestimoId}&data=${data}`, { responseType: 'blob' })
  },
  fichaCadastral (emprestimoId) {
    return api.get(`${base}ficha-cadastral?emprestimoId=${emprestimoId}`, { responseType: 'blob' })
  },
}
