<template>
  <div class="dashboard">
    <va-card :title="$t('ajuizamento.proximosAjuizar.title') + ` - ${filteredData.length} operações encontradas`">
      <div class="row align--center mb-1">
        <div class="flex xs12 sm6">
          <va-input
            class="ma-0"
            :value="term"
            :placeholder="$t('tables.searchByName')"
            @input="search"
            removable
          >
            <va-icon name="fa fa-search" slot="prepend" />
          </va-input>
        </div>
        <div class="flex xs12 md3 offset--md3">
          <va-select
            :options="perPageOptions"
            v-model="perPage"
            label="Itens por página"
          />
        </div>
      </div>

      <va-data-table
        :per-page="perPage"
        :fields="mode ? detailedFields : fields"
        :data="filteredData"
        :loading="loading"
        hoverable
      >
        <template slot="icon">
          <va-icon name="fa fa-user" color="secondary" />
        </template>
        <template v-slot:starred="props">
          <va-icon
            v-if="props.rowData.starred"
            name="fa fa-star"
            color="warning"
          />
        </template>
        <template v-slot:status="props">
          {{ props.rowData.status }}
        </template>
        <template slot="razaoSocial" slot-scope="props">
          <a :href="`https://zxvf.tutudigital.com.br/tomadores/${props.rowData.tomadorId}/dossie1`" target="_blank">
            {{props.rowData.razaoSocial}}
          </a>
        </template>
        <template v-slot:actions="props">
          <va-button
            small
            outline
            color="success"
            icon="fa fa-check"
            class="ma-0"
            @click="resolveUser(props.rowData)"
          >
            {{ $t('dashboard.table.resolve') }}
          </va-button>
        </template>
        <template slot="acoes" slot-scope="props">
          <a v-on:click="valorPresente(props.rowData)" title="Valor Presente" alt="Valor Presente" href="javascript://">
            V.P
          </a>
          | <a v-on:click="fichaCadastral(props.rowData)" title="Ficha Cadastral" alt="Ficha Cadastral" href="javascript://">
            F.C
          </a>
          | <a v-on:click="baixarCCBs(props.rowData)" title="CCBs" alt="CCBs" href="javascript://">
            CCBs
          </a>
          |
          <a v-on:click="baixarContratosInvestidores(props.rowData)" title="Contratos Investidores" alt="Contratos Investidores" href="javascript://">
            T.C </a>
          |
          <a v-on:click="baixarIrpfSocios(props.rowData)" title="IRPF Sócios" alt="IRPF Sócios" href="javascript://">
            IRPF
          </a>
        </template>
      </va-data-table>
    </va-card>
  </div>
</template>

<script>
import debounce from 'lodash/debounce'
import negativacoesService from '../../services/Cobranca/NegativacoesService'
import operacoesService from '../../services/Cobranca/OperacoesService'

export default {
  data () {
    return {
      operacoes: [],
      perPage: 15,
      perPageOptions: ['15', '25', '35', '50', '75', '100', '200', '300', '400', '500'],
      loading: false,
      term: null,
      mode: 0,
    }
  },
  computed: {
    fields () {
      return [{
        name: 'id',
        title: 'EmprestimoId',
      }, {
        name: '__slot:razaoSocial',
        title: 'Razão Social',
      }, {
        name: 'acordoId',
        title: 'AcordoId',
      },
      {
        name: 'statusAcordo',
        title: 'Status Acordo',
      },
      {
        name: 'diasVencidoPrimeiraParcela',
        title: 'Dias Vencido',
      },
      {
        name: 'negativado',
        title: 'CNPJ Neg.',
      },
      {
        name: 'todosAvalistasNegativados',
        title: 'Todos Avalistas Neg.',
      },
      {
        name: 'algumAvalistaNegativado',
        title: 'Algum Avalista Neg.',
      },
      {
        name: 'enviadoParaJuridico',
        title: 'Enviado para JURÍDICO',
      },
      {
        name: 'numeroProcesso',
        title: 'Número Processo',
      },
      {
        name: '__slot:acoes',
        title: 'Ações',
      }]
    },
    modeOptions () {
      return [{
        value: 0,
        label: this.$t('dashboard.table.brief'),
      }]
    },
    filteredData () {
      if (!this.term || this.term.length < 1) {
        return this.operacoes
      }

      return this.operacoes.filter(item => {
        return item.razaoSocial.toLowerCase().startsWith(this.term.toLowerCase())
      })
    },
  },
  methods: {
    async fichaCadastral (item) {
      const res = await operacoesService.fichaCadastral(item.id)

      const downloadUrl = window.URL.createObjectURL(new Blob([res.data]))
      const link = document.createElement('a')
      link.href = downloadUrl
      link.setAttribute('download', `${item.id} - ${item.razaoSocial} - Ficha Cadastral.pdf`) // any other extension
      document.body.appendChild(link)
      link.click()
      link.remove()
    },
    async baixarCCBs (item) {
      const res = await operacoesService.baixarCCBs(item.id)

      const downloadUrl = window.URL.createObjectURL(new Blob([res.data]))
      const link = document.createElement('a')
      link.href = downloadUrl
      link.setAttribute('download', `${item.id} - ${item.razaoSocial} - CCBs.zip`) // any other extension
      document.body.appendChild(link)
      link.click()
      link.remove()
    },
    async baixarContratosInvestidores (item) {
      const res = await operacoesService.baixarContratosInvestidores(item.id)

      const downloadUrl = window.URL.createObjectURL(new Blob([res.data]))
      const link = document.createElement('a')
      link.href = downloadUrl
      link.setAttribute('download', `${item.id} - ${item.razaoSocial} - Contratos Investidores.zip`) // any other extension
      document.body.appendChild(link)
      link.click()
      link.remove()
    },
    async baixarIrpfSocios (item) {
      const res = await operacoesService.baixarIrpfSocios(item.id)

      const downloadUrl = window.URL.createObjectURL(new Blob([res.data]))
      const link = document.createElement('a')
      link.href = downloadUrl
      link.setAttribute('download', `${item.id} - ${item.razaoSocial} - IRPF Sócios.zip`) // any other extension
      document.body.appendChild(link)
      link.click()
      link.remove()
    },
    async valorPresente (item) {
      window.Swal.fire({
        title: 'Informe a data para cálculo atualizado',
        input: 'text',
        inputAttributes: {
          autocapitalize: 'off',
        },
        inputValue: new Date().toLocaleString().substring(0, 10),
        showCancelButton: true,
        confirmButtonText: 'Gerar PDF',
        showLoaderOnConfirm: true,
        allowOutsideClick: () => !window.Swal.isLoading(),
      }).then(async (result) => {
        console.log(result)
        if (result.isConfirmed) {
          const res = await operacoesService.valorPresente(item.id, result.value)

          const downloadUrl = window.URL.createObjectURL(new Blob([res.data]))
          const link = document.createElement('a')
          link.href = downloadUrl
          link.setAttribute('download', `${item.id} - ${item.razaoSocial} - Valor Presente.pdf`) // any other extension
          document.body.appendChild(link)
          link.click()
          link.remove()
        }
      })
    },
    getStatusColor (status) {
      if (status === 'paid') {
        return 'success'
      }

      if (status === 'processing') {
        return 'info'
      }

      return 'danger'
    },
    search: debounce(function (term) {
      this.term = term
    }, 400),
  },
  async mounted () {
    this.operacoes = (await negativacoesService.seraoAjuizados()).data.data
  },
}
</script>
