import api from '@/plugins/axios'

const base = '/v1/negativacoes/'

export default {
  paraNegativar () {
    return api.get(`${base}prontos-negativar`)
  },
  paraNegativarCNPJ () {
    return api.get(`${base}prontos-negativar-cnpj`)
  },
  paraNegativarAvalistas () {
    return api.get(`${base}prontos-negativar-avalistas`)
  },
  negativados () {
    return api.get(`${base}negativados`)
  },
  seraoNegativados () {
    return api.get(`${base}serao-negativados`)
  },
  removerNegativacoes () {
    return api.get(`${base}remover-negativacoes`)
  },
  poderaoSerRemovidos () {
    return api.get(`${base}poderao-ser-removidos`)
  },
  paraAjuizar () {
    return api.get(`${base}prontos-ajuizar`)
  },
  ajuizados () {
    return api.get(`${base}ajuizados`)
  },
  seraoAjuizados () {
    return api.get(`${base}serao-ajuizados`)
  },
  quitados () {
    return api.get(`${base}quitados`)
  },
  adimplentes () {
    return api.get(`${base}adimplentes`)
  },
  informarNegativacao (body) {
    return api.post(`${base}informar-negativacao`, body)
  },
  informarNegativacaoAvalistas (body) {
    return api.post(`${base}informar-negativacao-avalistas`, body)
  },

  informarEnviadoJuridico (body) {
    return api.post(`${base}informar-enviado-juridico`, body)
  },
  incluirNumeroProcesso (body) {
    return api.post(`${base}incluir-numero-processo`, body)
  },
  reprocessarNegativacaoAcordo (acordoId) {
    return api.post(`${base}reprocessar-negativacao-emprestimo/${acordoId}`)
  },
  reprocessarNegativacaoEmprestimo (emprestimoId) {
    return api.post(`${base}reprocessar-negativacao-emprestimo/${emprestimoId}`)
  },
  resumoContratos () {
    return api.get(`${base}resumo-contratos`)
  },
  telefonesInadimplentes () {
    return api.get(`${base}telefones-inadimplentes`)
  },
}
